<template>
    <div class="exploreDetailDialog">
        <van-pull-refresh v-model="isLoading" @refresh="loadData">
            <p style="line-height:36px;margin:0;font-size: 14px;text-indent: 16px;color: #ccc;">店铺信息</p>
            <van-cell-group>
                <van-cell title="店铺名称" :value="message.shop_name" />
                <van-cell v-if="message.area.short_name" title="店铺地址" :value="message.area.short_name" />
                <van-cell v-if="message.address" title="详细地址" :value="message.address" />
                <van-cell v-if="message.platform" title="店铺平台" :value="message.platform" />
                <van-cell v-if="message.link_url" title="店铺链接">
                    <a :href="message.link_url.substr(message.link_url.indexOf('http'))">{{message.link_url}}</a>
                </van-cell>
            </van-cell-group>
            <p style="line-height:36px;margin:0;font-size: 14px;text-indent: 16px;color: #ccc;">探店信息</p>
            <van-cell-group>
<!--                <van-cell title="等级要求" :value="message.channel_name" />-->
                <van-cell title="探店时间" :value="message.date" />
                <van-cell title="发布时间" :value="message.created_at" />
                <van-cell title="稿费" :value="message.price_amount+'元'" />
<!--                <van-cell title="是否到店">-->
<!--                    <span :style="message.offline?'color:red;':''">{{message.offline? '需要到店': '不需要'}}</span>-->
<!--                </van-cell>-->
            </van-cell-group>
            <p style="line-height:36px;margin:0;font-size: 14px;text-indent: 16px;color: #ccc;">探店要求&nbsp;(长按下方文字、图片可复制)</p>
            <van-cell-group>
                <van-cell @click="copy(message.remark)">{{message.remark}}</van-cell>
                <van-cell>
                    <div style="display: flex;flex-wrap: wrap;">
                        <van-image v-for="(item, index) in message.shop_img" style="flex: 0 0 33%;" :key="index"
                                   height="10rem" @click="imagePreview(message.shop_img,index)" fit="cover" :src="item" />
                    </div>
                </van-cell>
            </van-cell-group>

            <p style="line-height:36px;margin:0;font-size: 14px;text-indent: 16px;color: #ccc;">工单信息</p>
            <van-cell-group>
                <van-cell title="工单状态" :value="message.status_name" />
                <van-cell title="取消原因" v-if="message.error_reason" :value="message.error_reason" />
                <van-cell title="取消备注" v-if="message.reason_detail" :value="message.reason_detail" />
                <van-cell title="投稿链接" v-if="message.submit_remark" :value="message.submit_remark" />
                <van-cell title="投稿图片" v-if="message.submit_img" is-link @click="imagePreview([message.submit_img])" />
                <van-cell title="投稿时间" v-if="message.submit_time" :value="message.submit_time" />
                <van-cell title="验收备注" v-if="message.accept_remark" :value="message.accept_remark" />
                <van-cell title="验收时间" v-if="message.accept_time" :value="message.accept_time" />
                <van-cell title="结算备注" v-if="message.settlement_remark" :value="message.settlement_remark" />
                <van-cell title="结算时间" v-if="message.settlement_time" :value="message.settlement_time" />
            </van-cell-group>
            <p style="line-height:36px;margin:0;font-size: 14px;text-indent: 16px;color: #ccc;" v-if="message.hang_up_message.length">申诉信息</p>
            <van-cell-group v-if="message.hang_up_message.length">
                <van-cell title="申诉状态" :value="message.hang_up_message[0].feedback_name" />
                <van-cell title="拒回原因" :value="message.hang_up_message[0].refuse_reason" />
                <van-cell title="拒回时间" :value="message.hang_up_message[0].refused_at" />
                <van-cell title="申诉原因" :value="message.hang_up_message[0].appeal_remark" />
                <van-cell title="申诉时间" :value="message.hang_up_message[0].created_at" />
                <van-cell title="审核回复" v-if="message.hang_up_message[0].feedback_remark" :value="message.hang_up_message[0].feedback_remark" />
                <van-cell title="审核时间" v-if="message.hang_up_message[0].feedback_at" :value="message.hang_up_message[0].feedback_at" />
            </van-cell-group>
            <div style="height: 100px;width: 1px;"></div>
        </van-pull-refresh>
        <van-goods-action v-if="[0,1,2,3,5].includes(message.status)">
            <van-button v-if="message.status === 5 && message.hang_up === 0" style="margin-left:10px;height: 40px;width: 60px;" round text="申诉" @click="show = true" />
            <van-button v-if="message.status === 0 || (message.status === 5 && message.hang_up !== 1)" style="margin-left:10px;height: 40px;width: 100px;" round text="放弃任务" @click="show2 = true"></van-button>
            <van-goods-action-button v-if="message.status === 0" style="border-radius: 999px;" type="danger" text="上传探店凭证" @click="$router.push({ path: '/talent/upload', query: { work_id: message.work_id}})"/>
            <van-goods-action-button v-if="message.status === 1" style="border-radius: 999px;" type="danger" disabled text="待验收" @click="notify('投稿完成3天后将自动进入待结算阶段，请耐心等待')"/>
            <van-goods-action-button v-if="message.status === 2" style="border-radius: 999px;" type="danger" disabled text="已验收，等待结算"  @click="notify('投稿完成3天后将自动进入待结算阶段，请耐心等待')"/>
            <van-goods-action-button v-if="message.status === 3" style="border-radius: 999px;" type="danger" disabled text="验收通过，待结算"  @click="notify('请等待工作人员审核，审核通过后将自动发送微信红包到您的账号，请注意查收')"/>
            <van-goods-action-button v-if="message.status === 5 && message.hang_up !== 1" style="border-radius: 999px;" type="danger" text="重新上传"  @click="$router.push({ path: '/talent/upload', query: { work_id: message.work_id }})"/>
            <van-goods-action-button v-if="message.status === 5 && message.hang_up === 1" style="border-radius: 999px;" type="danger" text="申诉中" disabled/>
        </van-goods-action>
        <van-dialog v-model="show2" title="放弃任务" show-cancel-button :before-close="giveUp">
            <van-cell-group>
                <van-field
                        v-model="give_up_remark"
                        rows="5"
                        autosize
                        type="textarea"
                        placeholder="请输入放弃原因"
                />
            </van-cell-group>
        </van-dialog>
        <van-dialog v-model="show" title="申诉" show-cancel-button :before-close="appeal">
            <van-cell-group>
                <van-field
                        v-model="appeal_remark"
                        rows="5"
                        autosize
                        label="申诉内容"
                        type="textarea"
                        placeholder="请输入申诉内容"
                />
            </van-cell-group>
        </van-dialog>

    </div>
</template>

<script>
    import {Toast, Dialog, ImagePreview, Checkbox, Notify} from 'vant';
    import {getQueryVariable} from "../../utils/public";
    import wx from "weixin-js-sdk";
    import {mapState} from 'vuex';
    export default {
        name: "ExploreDetail",
        data(){
            return{
                work_id:'',
                message:{
                    area:{},
                    status:[],
                    hang_up_message:[],
                },
                isLoading: false,
                checked: false,
                show:false,
                show2:false,
                appeal_remark:'',//申诉内容
                give_up_remark:'',//放弃原因
            }
        },
        computed:{
            ...mapState(['common']),
        },
        async mounted() {
            if(this.$route.query.work_id){
                this.work_id = this.$route.query.work_id;
            } else {
                this.work_id = getQueryVariable('work_id');
            }

            // 加载店铺信息
            await this.loadData();

        },
        methods:{
            copy(message){
                this.$copyText(message).then(res => {
                    Toast('已复制到剪贴板');
                }).catch(err => {
                    alert('Can not copy');
                })
            },
            loadData(){
                return new Promise((resolve, reject) => {
                    this.post('/api/client/shop/explore/invite',{work_id:this.work_id}).then(result=>{
                        if(result.code === 0){
                            this.message = result.data;
                            this.isLoading = false;
                            resolve();
                        }
                    });
                })
            },
            imagePreview(src,index = 0){
                ImagePreview({
                    images: src,
                    startPosition: index,
                    closeable: true,
                    closeOnPopstate:true,
                });
            },
            notify (message,type='primary') {
                Notify({type: type, message: message});
            },
            giveUp(action,done){
                let that = this;
                if(action !== 'confirm'){
                    done();
                    return;
                }
                if(!this.give_up_remark){
                    Toast('请输入放弃原因');
                    done(false);
                    return;
                }
                Dialog.confirm({
                    title: '请确认',
                    message: '您将放弃这个任务',
                }).then(() => {
                    that.post('/api/client/shop/explore/give_up', {work_id: that.work_id,remark:that.give_up_remark,}).then(result => {
                        if (result.code === 0) {
                            done();
                            Dialog.alert({
                                title: '提示',
                                message: '您已放弃成功',
                            })
                            // 重新加载
                            that.loadData();
                        } else {
                            done(false);
                            Dialog.alert({
                                title: '操作失败',
                                message: result.msg,
                            });
                        }
                    }).catch(e=>{
                        Toast('服务器错误');
                        done(false);
                    });

                }).catch(()=>{
                    done();
                });
            },
            appeal(action,done){
                let that = this;
                if(action !== 'confirm'){
                    done();
                    return;
                }
                if(!this.appeal_remark){
                    Toast('请输入申诉内容');
                    done(false);
                    return;
                }
                Dialog.confirm({
                    title: '请确认',
                    message: '您将申请客服介入判断验收',
                }).then(() => {
                    that.post('/api/client/shop/explore/appeal', {work_id: that.work_id,appeal_remark:that.appeal_remark,}).then(result => {
                        if (result.code === 0) {
                            done();
                            Dialog.alert({
                                title: '申诉成功',
                                message: result.msg,
                            }).then(()=>{
                                // 重新加载
                                that.loadData();
                            })
                        } else {
                            done(false);
                            Dialog.alert({
                                title: '操作失败',
                                message: result.msg,
                            });
                        }
                    }).catch(e=>{
                        Toast('服务器错误');
                        done(false);
                    });

                }).catch(()=>{
                    done();
                });
            }
        }
    }
</script>

<style>
    .exploreDetailDialog .van-dialog__header{
        padding: 16px 0 8px;
    }
</style>